<template>
    <select
        :value="modelValue === null ? null : modelValue[option_value]"
        @input="fire($event)"
        class="form-select"
        :required="required"
    >
        <template v-if="empty_option">
            <option :value="null">{{ empty_option }}</option>
        </template>
        <template v-for="option in options" :key="option">
            <option :value="option[option_value]">{{ option[option_label] }}</option>
        </template>
    </select>
</template>

<script>
export default {
    name: 'FormSelectObject',
    props: {
        modelValue: {
            type: Object,
        },
        options: {
            type: Array
        },
        option_value: {
            type: String,
            default: 'value'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        empty_option: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'update:modelValue',
        'selected',
    ],
    methods: {
        fire($event) {
            // HTML (JS) の仕様で、value が未指定の場合、select の value はラベルの文字列となる
            if ($event.target.value === null || $event.target.value === '' || $event.target.value === this.empty_option) {
                this.$emit('update:modelValue', null);
                this.$emit('selected', null);
            } else {
                let new_object = this.options.find(option => option[this.option_value] === parseInt($event.target.value, 10));
                this.$emit('update:modelValue', new_object);
                this.$emit('selected', new_object);
            }
        }
    }
}
</script>

<style>
.form-select-search .multiselect-multiple-label,
.form-select-search .multiselect-placeholder,
.form-select-search .multiselect-single-label {
    line-height: 1 !important;
}
</style>
<style scoped>
.multiselect-blue {
    --ms-line-height: 1.2;
    /* --ms-font-size: 0.9rem; */
    --ms-option-bg-selected: var(--bs-primary);
    --ms-option-bg-selected-pointed: var(--bs-primary);
    --ms-py: 0.47rem;
    /* --ms-option-font-size: 0.9rem; */
}
</style>