<template>
    <header class="page-title">
        <h1><i class="bi bi-gear"></i> ユーザ登録</h1>
        <router-link class="btn btn-outline-secondary" :to="{name: 'SystemUser'}"><i class="bi bi-reply"></i> 一覧に戻る</router-link>
    </header>

    <section class="section">
        <form @submit.prevent="save">
            <div class="row mb-3 align-items-end">
                <div class="col-6">
                    <label class="form-label">氏名</label>
                    <form-input v-model="user.user_name" required></form-input>
                </div>
                <div class="col-12">
                    <label class="form-label">メールアドレス</label>
                    <form-input v-model="user.email" type="email" required></form-input>
                </div>
                <div class="col-6">
                    <form-checkbox option_label="招待メールを送信" v-model="send_invitation"></form-checkbox>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">在席期間</label>
                    <div class="input-group">
                        <form-input-month v-model="user.member_from" required></form-input-month>
                        <div class="input-group-text">〜</div>
                        <form-input-month v-model="user.member_to"></form-input-month>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">部門</label>
                    <form-select-object v-model="user.department" :options="departments" option_value="department_id" option_label="department_name" empty_option="-- 選択 --" :disabled="departments.length === 0"></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">標準労務単価</label>
                    <form-input-number v-model="labor_cost" required></form-input-number>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-24">
                    <label class="form-label">権限</label>
                    <form-checkboxes v-model="user.permissions" :options="Permission.options()"></form-checkboxes>
                </div>
            </div>
            <div class="mt-5">
                <button class="btn btn-info btn-lg">登録</button>
            </div>
        </form>
    </section>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormCheckboxes from '@/components/forms/FormCheckboxes';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormCheckbox from '@/components/forms/FormCheckbox';
import User from "@/models/entities/user";
import Department from '@/models/entities/department';
import Permission from '@/models/enums/permission';
import { thisMonth } from '@/utilities/month'

export default {
    name: 'SystemUserAdd',
    components: {
        FormInput,
        FormInputNumber,
        FormCheckboxes,
        FormSelectObject,
        FormCheckbox,
        FormInputMonth,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            user: new User({
                member_from: thisMonth(),
                permissions: [Permission.VIEW_OTHERS_REPORT, Permission.VIEW_PROFIT, Permission.MANAGE_MASTER]
            }),
            send_invitation: true,
            labor_cost: null,
            departments: [],
            Permission,
        }
    },
    mounted() {
        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        save() {
            this.startScreenLoading();
            let param = Object.assign({
                send_invitation: this.send_invitation,
                labor_cost: this.labor_cost,
            }, this.user);

            this.$http.post('/system/user', param)
            .then(() => {
                this.showMessage('登録しました');
                this.$router.push({name: 'SystemUser'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    },
}
</script>

<style scoped>
</style>
